@import url(https://fonts.googleapis.com/css?family=Saira+Extra+Condensed&display=swap);
@import url(https://fonts.googleapis.com/css?family=Muli&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Oswald:wght@200;300;400;500;600;700&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Oswald', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  font-weight: 700;
  /* text-transform: uppercase; */
}

h1 {
  font-size: 5.5rem;
  line-height: 5.5rem;
}

h2 {
  font-size: 2.75rem;
}

h3 {
  font-size: 2rem;
}

.primary {
  color: #102a4c;
}

.icons {
  display: inline-block;
  margin: 10px;
}

.icon {
  width: 60px;
  height: 60px;
  margin: 5px;
}

.container {
  background-color: #fff;
  height: 100%;
  width: 100%;
  font-family: 'Saira Extra Condensed';
}

.link {
  color: #cfe9d8;
  display: block;
  padding: 0.5rem 1rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  text-decoration: none;
  font-weight: 800;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  font-size: 1rem;
  font-family: 'Lato';
}

.link:hover {
  color: #fff;
  text-decoration: none;
}

.active {
  color: #fff;
}

.brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.5rem;
  line-height: inherit;
  white-space: nowrap;

  font-weight: 800;
  letter-spacing: 0.05rem;
  font-family: 'Oswald';
}

p,
li,
.text {
  font-family: 'Lato';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

/* NavBar */
.navbar {
  background-color: #102a4c;
  font-family: 'Lato';
}

.brandText {
  text-decoration: none;
  color: #fff;
}

.brandText:hover {
  color: #fff;
  text-decoration: none;
}

/* Home */

@media (min-width: 1200px) {
  .intro {
    background: url(/static/media/intro.efb77d21.jpeg);
    background-attachment: fixed;
    background-size: cover;
    background-repeat: repeat-x;
    background-position: 30%;
    width: 100%;
    height: calc(100vh - 62px);
    max-height: calc(100vh - 62px);
    animation: animatedBackgroundDesktop 7s linear;
    -moz-animation: animatedBackgroundDesktop 7s linear;
    -webkit-animation: animatedBackgroundDesktop 7s linear;
    -ms-animation: animatedBackgroundDesktop 7s linear;
    -o-animation: animatedBackgroundDesktop 7s linear;
  }
}

@media (max-width: 1199px) {
  .intro {
    background: url(/static/media/intro.efb77d21.jpeg);
    background-position: 30%;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
    height: calc(100vh - 62px);
    animation: animatedBackground 7s linear;
    -moz-animation: animatedBackground 7s linear;
    -webkit-animation: animatedBackground 7s linear;
    -ms-animation: animatedBackground 7s linear;
    -o-animation: animatedBackground 7s linear;
  }
  .introTitle {
    padding-left: 5vw !important;
  }
}

@keyframes animatedBackgroundDesktop {
  0% {
    background-position: 6cm;
  }
  100% {
    background-position: 0cm;
  }
}

@keyframes animatedBackground {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 30%;
  }
}

.introTitle {
  padding-top: 30vh;
  padding-left: 10vw;
  padding-right: 30px;
}

.item {
  background-color: #f8f8f8;
  margin: 10px;
}

.subheading {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 1.25rem;
  color: #868e96;
}

.profilePic {
  width: 15rem;
  height: 15rem;
}

/* Links */
.links {
  position: fixed;
  right: 0;
  top: 50%;
  margin-right: 10px;
  transform: translateY(-50%);
  z-index: 1;
}

.linkIcon {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #102a4c;
  position: relative;
}

/* Footer */
.footerSection {
  background-color: #102a4c;
  color: #fff;
  width: 100%;
  height: 100%;
}

.footerLink {
  color: #cfe9d8;
  font-family: 'Lato';
}

.footerLink:hover {
  color: #fff;
  text-decoration: none;
}

.footer-menu {
  display: flex;
}

.footerIcons {
  padding: 20px;
  background-color: #cfe9d8;
}

.footerIcon {
  width: 30px;
  height: 30px;
  margin: 20px;
  color: #102a4c;
  position: relative;
}

@media screen and (max-width: 768px) {
  .links {
    display: none;
  }
  .linkIcon {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .footerIcons {
    display: none;
  }
  .footerIcon {
    display: none;
  }
}

/* Card */
.card {
  max-width: 300px;
  box-shadow: 3px 3px 5px #ccc;
}

/* Portfolio Page */
.date {
  border-bottom: 1px dotted #ccc;
}

.techstack {
  border-bottom: 1px dotted #ccc;
}

.techstackimage {
  width: 50px;
  height: 50px;
}

.githubButton {
  width: 20px;
  height: 20px;
}

.mockupImage {
  width: 100%;
}

.mobileImage {
  width: 50%;
  padding: auto;
}

.exampleImage {
  width: 100%;
}

.preImage {
  position: relative;
}

.preImage::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom right, #e2e6a6, #e069e8);
  opacity: 0.65;
}

.preImage:hover > .cardImage {
  filter: grayscale(0);
}

.preImage:hover::after {
  opacity: 1;
  background-image: none;
}

.cardImage {
  filter: grayscale(1);
}


.fixFooter {
  min-height: calc(100vh - 76px - 162px);
}

/* Contact */
.formCSS {
  font-family: 'Muli';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
}

.btn-primary {
  background-color: #102a4c;
  border-color: #102a4c;
}

.btn-outline-primary {
  border-color: #102a4c;
  color: #102a4c;
}

.btn-outline-primary:hover {
  border-color: #102a4c;
  background-color: #102a4c;
}

.color-white {
  color: #fff;
}

.introButton {
  background-color: #cfe9d8;
  border-color: #cfe9d8;
  color: #102a4c;
  font-family: 'Lato';
}

.introButton:hover {
  background-color: #102a4c;
  color: #cfe9d8;
  border-color: #102a4c;
}

.introOutlineButton {
  color: #cfe9d8;
  border-color: #cfe9d8;
}

.introOutlineButton:hover {
  background-color: #cfe9d8;
  border-color: #cfe9d8;
  color: #102a4c;
}

.newButton {
  background-color: #102a4c;
  border-color: #102a4c;
  color: #cfe9d8;
  font-family: 'Lato';
}

.newButton:hover {
  background-color: #cfe9d8;
  border-color: #cfe9d8;
  color: #102a4c;
}

.newButtonOutline {
  background-color: #ffffff;
  border-color: #102a4c;
  color: #102a4c;
  font-family: 'Lato';
}

.newButtonOutline:hover {
  border-color: #102a4c;
  background-color: #102a4c;
  color: #fff
}

.colorPrimary {
  color: #102a4c;
}

.colorSecondary {
  color: #cfe9d8;
}

.textUnderline {
  text-decoration: underline;
}

.linkIconContact {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #102a4c;
  position: relative;
}

